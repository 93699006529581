import { Archive, Check, Clock, Filter, GoogleDocs, InfoEmpty, Minus, MoneySquare, PageEdit, ShoppingBagRemove, WarningCircle } from "iconoir-react";
import { LoanReviewStatus } from "src/backend";
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip";
import { getReviewStatusTooltip } from "src/utils/loan/get-review-status-tooltip";

import { LoanReviewIconStyles } from "./loan-review-icon.styles";

interface LoanReviewIconProps {
    status: LoanReviewStatus;
}

export const LoanReviewIcon = (props: LoanReviewIconProps) => (<MysherpasTooltip
    open={(props.status === LoanReviewStatus.IN_PROGRESS || !props.status) ? false : undefined}
    title={getReviewStatusTooltip(props.status)}>
    <LoanReviewIconStyles.Wrapper
        data-status={props.status}
        className='loan-ready-for-review-icon-wrapper' >
        {props.status === LoanReviewStatus.READY_FOR_REVIEW_PENDING && <Clock fr="" />}
        {[LoanReviewStatus.REVIEW_COMPLETE_ACCEPTED, LoanReviewStatus.CLOSING].includes(props.status) && <Check fr="" />}
        {props.status === LoanReviewStatus.READY_FOR_REVIEW_REVIEW && <PageEdit fr="" />}
        {props.status === LoanReviewStatus.READY_FOR_UNDERWRITING && <GoogleDocs fr="" />}
        {props.status === LoanReviewStatus.ARCHIVE && <Archive fr="" />}
        {props.status === LoanReviewStatus.LEAD && <Filter fr="" />}
        {props.status === LoanReviewStatus.IN_SERVICE && <MoneySquare fr="" />}
        {props.status === LoanReviewStatus.IN_DEFAULT && <InfoEmpty fr="" />}
        {props.status === LoanReviewStatus.WITHDRAWN && <ShoppingBagRemove fr="" />}
        {props.status === LoanReviewStatus.REVIEW_COMPLETE_CONDITIONAL && <WarningCircle fr="" />}
        {[LoanReviewStatus.REVIEW_COMPLETE_REJECTED, LoanReviewStatus.HOLD].includes(props.status) && <Minus fr="" />}
    </LoanReviewIconStyles.Wrapper>
</MysherpasTooltip>)