import { darken, styled } from "@mui/material/styles";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const ProgressContainer = styled('div')(props => {

    return ({
        flex: 1,
        padding: 0,
        height: '100%',
        backgroundColor: props.theme.palette.background.paper,
        color: props.theme.palette.text.primary,
        '& > span': {
            color: darken(props.theme.palette.text.primary, .2)
        },
        position: 'absolute',
        width: '100%',
        '&[data-primary="true"]': {
            backgroundColor: props.theme.palette.primary.light,
            color: props.theme.palette.primary.dark
        },
        '&[data-warning="true"]': {
            backgroundColor: themeV2.colors.yellow[100],
            color: themeV2.colors.yellow[100]
        },
        '&[data-success="true"]': {
            backgroundColor: themeV2.colors.green[50],
            color: themeV2.colors.green[50]
        }
    })
});

const RowBox = styled('div')(({ theme }: { theme }) => ({
    height: 10,
    backgroundColor: theme.palette.grey[300],
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    borderRadius: 8,
}));

export const LoanProgressBarStyles = {
    ProgressContainer,
    RowBox
}