import { j as e } from "./jsx-runtime-944c88e2.js";
import r, { Children as B, isValidElement as h, forwardRef as N } from "react";
import f from "./Button.es.js";
import c from "./DropdownMenu.es.js";
import u from "./Icon.es.js";
import j from "./Text.es.js";
import { g as k } from "./utils-428888d8.js";
import "./index-bf9f2594.js";
import "./index-31854cc2.js";
import "./index-5499c00c.js";
import "./index-61f4edff.js";
import "./index-14f22d4f.js";
import "react-dom";
import "./index-a88fc5a6.js";
import "./index-1fe761a6.js";
import "./index-6b71901e.js";
import "./index-760cd071.js";
import "./Combination-ba9973a9.js";
import "./index-7ed7ad30.js";
import "./index-62dc40ef.js";
import "./floating-ui.react-dom-c6515077.js";
import "./index-a1ad2cc2.js";
import "./index-e1c48dc5.js";
import "iconoir-react";
const x = (t) => {
  const a = B.only(t.children);
  return /* @__PURE__ */ e.jsx(
    f,
    {
      asChild: !0,
      variant: "ghost",
      className: "w-[28px] h-[28px] p-0 shrink-0 cursor-pointer",
      children: /* @__PURE__ */ e.jsx("div", { children: h(a) && r.cloneElement(
        a,
        {},
        /* @__PURE__ */ e.jsx(u, { name: "NavArrowLeft", width: 20 })
      ) })
    }
  );
};
x.displayName = "BreadcrumbsBackButton";
const w = (t) => /* @__PURE__ */ e.jsx(j, { "data-before": "/", as: "div", className: t.className, children: t.children });
w.displayName = "Breadcrumb";
const C = (t, a) => {
  const [o, n] = r.useState(!1), i = r.useRef(null);
  r.useImperativeHandle(a, () => ({
    openDropdown: () => n(!0),
    closeDropdown: () => n(!1)
  }));
  const m = r.Children.toArray(t.children).filter(
    (s) => h(s) && typeof s.type != "string" && s.type.displayName === "Breadcrumb"
  ), [l, g] = r.useState(m), d = r.useCallback(() => {
    const s = i.current;
    if (!s)
      return;
    s.scrollWidth > s.clientWidth && l.length > 1 && g(
      (p) => p.slice(1, p.length)
    );
  }, [l.length]);
  r.useLayoutEffect(() => (d(), window.addEventListener("resize", d), () => {
    window.removeEventListener("resize", d);
  }), [d]);
  const v = m.slice(
    0,
    m.length - l.length
  ), y = k(
    t.children,
    "BreadcrumbsBackButton"
  );
  return {
    dropdownItems: v,
    children: l,
    containerRef: i,
    backButton: y,
    isDropdownOpen: o,
    onDropdownOpenChange: n
  };
}, b = N((t, a) => {
  const o = C(t, a);
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      className: "flex gap-2 w-full flex-row items-center py-2",
      ref: o.containerRef,
      children: [
        o.backButton,
        o.dropdownItems.length > 0 && /* @__PURE__ */ e.jsxs(
          c,
          {
            open: o.isDropdownOpen,
            onOpenChange: o.onDropdownOpenChange,
            children: [
              /* @__PURE__ */ e.jsx(
                c.Trigger,
                {
                  "data-after": "/",
                  className: "after:content-[attr(data-after)] after:text-black-primary after:-mr-12 after:absolute",
                  asChild: !0,
                  children: /* @__PURE__ */ e.jsx(
                    f,
                    {
                      variant: "ghost",
                      className: "w-[28px] h-[28px] p-0 hover:bg-gray-table-header",
                      children: /* @__PURE__ */ e.jsx(u, { strokeWidth: 2, name: "MoreHoriz", width: 23, height: 23 })
                    }
                  )
                }
              ),
              /* @__PURE__ */ e.jsx(
                c.Content,
                {
                  align: "start",
                  side: "bottom",
                  className: "w-[224px] min-w-0 flex flex-col",
                  sideOffset: 8,
                  children: o.dropdownItems.map((n, i) => /* @__PURE__ */ e.jsx(c.Item, { children: r.cloneElement(
                    n,
                    {
                      className: "flex overflow-hidden items-center [&>a_svg]:w-5 [&>a_svg]:h-4 [&>a_svg]:shrink-0 text-ellipsis gap-2 [&>a]:w-[224px] [&>a]:flex [&>a]:items-center [&>a]:gap-2 [&>a_p]:text-ellipsis [&>a]:overflow-hidden [&>a_p]:overflow-hidden [&>a_p]:whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
                    },
                    n.props.children
                  ) }, i))
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ e.jsx("div", { className: "flex-1 flex group min-w-0", children: o.children.map(
          (n) => r.cloneElement(n, {
            className: `${n.props.className ?? ""}
              cursor-pointer [&_svg]:hidden text-gray-secondary relative font-medium only:text-ellipsis only:overflow-hidden only:cursor-default last:text-black-primary first:before:hidden hover:text-black-primary whitespace-pre rounded px-1 hover:bg-gray-table-header before:content-[attr(data-before)] before:text-black-primary before:-ml-3 before:absolute ml-2`
          })
        ) })
      ]
    }
  );
});
b.displayName = "Breadcrumbs";
const Z = Object.assign(b, {
  BackButton: x,
  Breadcrumb: w
});
export {
  Z as default
};
