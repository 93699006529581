import Typography from "@mui/material/Typography"
import { Breakpoint } from "@mui/system"
import React, { PropsWithChildren } from "react"

import { DialogContainer } from "../dialog-container"
import { Button } from "../v2/button"
import { GenericConfirmationDialogStyles } from "./generic-confirmation-dialog.styles"

export interface GenericConfirmationDialogProps {
    open: boolean
    title: React.ReactNode
    disablePortal?: boolean
    hideBackdrop?: boolean
    message: React.ReactNode
    maxWidth?: Breakpoint
    confirmButtonText?: string
    cancelButtonText?: string
    onConfirm?: () => void
    onCancel?: () => void
}

export const GenericConfirmationDialog = (props: PropsWithChildren<GenericConfirmationDialogProps>) => {

    if (!props.open) {
        return null
    }

    return <DialogContainer
        open
        disablePortal={props.disablePortal}
        maxWidth={props.maxWidth}
        hideBackdrop={props.hideBackdrop}
        onClose={props.onCancel}>
        <GenericConfirmationDialogStyles.DialogContent>
            {props.children}
            <Typography
                sx={{
                    overflow: "hidden",
                    width: "100%",
                }}
                variant='subtitle1'>
                {props.title}
            </Typography>
            {props.message}
        </GenericConfirmationDialogStyles.DialogContent>
        <GenericConfirmationDialogStyles.DialogActions>
            {props.onCancel && <Button
                sx={{ width: 140 }}
                variant='outlined'
                color='secondary'
                onClick={props.onCancel}>
                {props.cancelButtonText}
            </Button>}
            {props.onConfirm && <Button
                sx={{ width: !props.onCancel ? '100%' : 140 }}
                variant='contained'
                color='primary'
                onClick={props.onConfirm}>
                {props.confirmButtonText}
            </Button>}
        </GenericConfirmationDialogStyles.DialogActions>
    </DialogContainer>
}

GenericConfirmationDialog.defaultProps = {
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    maxWidth: 'sm'
}