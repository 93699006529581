import { useEffect } from "react";
import { ListItem, LoanPhaseListItem, phaseCategoriesList } from "src/slices/lists";
import { addLoanStage, archiveLoanStagesSelector, deleteLoanStage, getLoanStagesByCompanyId, leadLoanStagesSelector, loanStagesSelector, originationLoanStagesSelector, portfolioLoanStagesSelector } from "src/slices/loan-stages";
import { useDispatch, useSelector } from "src/store";
import { LoanPhaseDto } from "src/types/api";
import { Optional } from "src/types/common";

interface ReturnValue {
    phases: LoanPhaseDto[];
    originationPhases: LoanPhaseDto[];
    archivePhases: LoanPhaseDto[];
    portfolioPhases: LoanPhaseDto[];
    leadPhases: LoanPhaseDto[];
    firstPortfolioPhase: LoanPhaseDto,
    firstArchivePhase: LoanPhaseDto,
    firstLeadPhase: LoanPhaseDto,
    categories: LoanPhaseListItem[];
    onDelete: (id: string) => void;
    onSave: (stage: Optional<LoanPhaseDto, 'id'>[]) => void;
    onCompanyChange: (companyId: string) => void;
}

type Props = {
    companyId?: string;
}

export const useLoanStages = ({ companyId }: Props = {}): ReturnValue => {
    const dispatch = useDispatch();
    const phases: LoanPhaseDto[] = useSelector(loanStagesSelector);
    const originationPhases = useSelector(originationLoanStagesSelector);
    const leadPhases = useSelector(leadLoanStagesSelector);
    const archivePhases = useSelector(archiveLoanStagesSelector);
    const portfolioPhases = useSelector(portfolioLoanStagesSelector);

    const categories: LoanPhaseListItem[] = useSelector(phaseCategoriesList);

    const handleDelete = (id: string) => {
        const phase = phases.find(s => s.id === id);
        dispatch(deleteLoanStage([phase]));
    }

    const handleSave = (phases: LoanPhaseDto[]) => {
        dispatch(addLoanStage(phases));
    }

    const handleCompanyChange = (companyId: string) => {
        dispatch(getLoanStagesByCompanyId(companyId));
    }

    useEffect(() => {
        if (!!companyId) {
            dispatch(getLoanStagesByCompanyId(companyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const firstPortfolioPhase = portfolioPhases?.[0];
    const firstArchivePhase = archivePhases?.[0];
    const firstLeadPhase = leadPhases?.[0];

    return {
        phases,
        originationPhases,
        archivePhases,
        portfolioPhases,
        leadPhases,
        categories,
        firstPortfolioPhase,
        firstArchivePhase,
        firstLeadPhase,
        onDelete: handleDelete,
        onSave: handleSave,
        onCompanyChange: handleCompanyChange
    };
}