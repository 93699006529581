import { Box, Typography } from "@mui/material";
import { Loan } from "src/types/loan";
import { LoanCalculations } from "src/utils/loan/calculations/loan-calculations";
import { isLoanPhaseActive } from "src/utils/loan/is-loan-phase-active";
import { formatAsCurrency, moneyFormatter } from "src/utils/money-formatter";

type Props = {
    shorten?: boolean,
    loan: Loan
}

export const LoanBlurbText = (props: Props): JSX.Element => {
    const { loan, shorten } = props;
    const toShortenOrNot = !shorten ? formatAsCurrency : moneyFormatter
    const calculations = new LoanCalculations(loan);

    return <Typography
        component='div'
        variant="body2"
        color='secondary'
        fontWeight={400}
        fontSize={12}>
        {loan?.templateProjectName ? <span>{loan?.templateProjectName}. </span> : null}
        Lending {formatAsCurrency(loan.loanAmount, 2)}, <strong>{toShortenOrNot(loan.loanAmount)}</strong> @ <strong> {loan?.apr?.toFixed(2) ?? '??'}%</strong> for <strong> {loan?.loanTermInMonths ?? '??'}</strong> months @ {calculations.getLoanCLTV() ? `${calculations.getLoanCLTV().toFixed(1)}%` : "??"} {calculations.getLoanEntitiesCount() > 1 ? 'C' : ''}LTV
        {isLoanPhaseActive(loan?.loanPhase?.category) && <>
            {` closing`} <strong>{loan?.closeDateFormatted}</strong> (<Box component='strong' sx={{ color: loan?.closeDateIsPassed ? 'error.main' : 'inherit' }}>
                {loan?.closeDateIsPassed ? 'PAST DUE' : loan?.closeDateDistance}
                {!loan?.closeDateFormatted && 'TBD'}
            </Box>)
        </>}
        {!isLoanPhaseActive(loan?.loanPhase?.category) && <>
            <strong>CLOSED {loan?.closeDateYearFormatted}</strong>
        </>}
        .
    </Typography>;
}