import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { themeV2 } from "src/theme/mysherpas-theme-option";

export const Button = styled(MuiButton)`
    &.MuiButton-primary {
        background-color: ${themeV2.colors.blue[100]};
    }
    &.MuiButton-outlinedSecondary {
        border-color: ${themeV2.neutral.grey[80]};
        color:  ${themeV2.colors.blue[800]};
    }
    &.MuiButton-containedPrimary {
        background-color: ${themeV2.colors.blue[100]};
    }
`