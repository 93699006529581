import { AvatarGroup } from "@mui/material";
import { LoanRoleDto } from "src/backend";
import { RoleType } from "src/constants/loan";
import { LoanRole } from "src/types/loan";
import { getInitials } from "src/utils/get-initials";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

import { MysherpasTooltip } from "../common/mysherps-tooltip/mysherpas-tooltip.component";
import { UserAvatar } from "../user/user-avatar";

export const RolesAvatars = ({ roles }: { roles: LoanRoleDto[] }) => <AvatarGroup
    max={6}
    sx={{
        paddingRight: '7px',
        pb: 0,
        '& .MuiAvatarGroup-avatar': {
            marginRight: '-7px',
            ml: 0
        },
        '& .MuiAvatar-root': {
            width: 20,
            height: 20,
        }
    }}>
    {[...roles].sort(sortByRole).map((role) => <MysherpasTooltip
        title={getUserDisplayName(role.user)}
        key={role.user.id}>
        <UserAvatar
            role={role.role}
            userId={role.user.id} >
            {getInitials(getUserDisplayName(role.user))}
        </UserAvatar>
    </MysherpasTooltip>)}
</AvatarGroup>

const sortByRole = (a: LoanRole, b: LoanRole) => {
    // sort by role LeadLender first then Lender then LeadBorrower then Borrower
    if (a.role === RoleType.LeadLender && b.role !== RoleType.LeadLender) {
        return -1;
    }
    if (a.role === RoleType.LENDER && b.role === RoleType.LeadBorrower) {
        return -1;
    }
    if (a.role === RoleType.LENDER && b.role === RoleType.BORROWER) {
        return -1;
    }
    if (a.role === RoleType.LeadBorrower && b.role === RoleType.BORROWER) {
        return -1;
    }
    return 1;

}
