import { MysherpasTooltip } from "src/components/common/mysherps-tooltip/mysherpas-tooltip.component";
import { JestTestId } from "src/constants/tests";
import { Loan } from "src/types/loan";

import { LoanProgressBarStyles } from "./loan-progress-bar.styles";

interface LoanProgressBarProps {
    loan: Loan;
}
export const LoanProgressBar = (props: LoanProgressBarProps) => {
    const items = [{
        label: 'Loan Progress',
        value: props.loan.loanProgress,
        color: 'primary',
        zIndex: 2
    }, {
        label: 'Answered',
        value: props.loan.percentageAnswered,
        color: 'warning',
        zIndex: 1
    }, {
        label: 'Accepted',
        value: props.loan.percentageApproved,
        color: 'success',
        zIndex: 3
    }];

    return (<LoanProgressBarStyles.RowBox data-testid={JestTestId.LoanProgressBar}>
        {items.map(item => <MysherpasTooltip
            placement='top-end' arrow={false} title={item.label} key={item.label}>
            <LoanProgressBarStyles.ProgressContainer
                key={item.label}
                style={{ width: `${item.value}%`, zIndex: item.zIndex }}
                data-primary={item.color === 'primary'}
                data-warning={item.color === 'warning'}
                data-success={item.color === 'success'}>
            </LoanProgressBarStyles.ProgressContainer>
        </MysherpasTooltip>)}

    </LoanProgressBarStyles.RowBox>)
};