import MuiChip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "8px 18px",
    height: 218,
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 8,
    maxHeight: 218,
    overflow: 'hidden',
})

const Header = styled("div")({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .LoanOverviewCardActions': {
        display: 'flex',
        alignItems: 'center',
    }
})

const Title = styled("h2")(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    padding: 0,
    margin: 0,
    alignItems: 'center',
    gap: theme.spacing(1),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    minWidth: 0,
    display: 'inline-block',
}))

const EmptyText = styled("p")(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    padding: 0,
    margin: 0,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: themeV2.neutral.grey[100],
}))

const Chip = styled(MuiChip)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: themeV2.colors.blue[900],
    minWidth: 20,
    padding: 0,
    height: 20,
    '& .MuiChip-label': {
        padding: 0,
        color: themeV2.colors.blue[900],
    }
}))

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        "&::before": {
            backgroundColor: theme.palette.common.white,
            border: "1px solid #999"
        }
    },
    [`& .${tooltipClasses.tooltip}`]: {
        minWidth: 326,
        maxWidth: 'auto',
        width: 'auto',
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
        fontSize: 14,
        // padding: theme.spacing(3.5, 2.25),
    },
}));

export const LoanOverviewCardStyles = {
    Container,
    Header,
    Title,
    EmptyText,
    LightTooltip,
    Chip
}